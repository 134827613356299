import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import StrapiHtml from '@templates/strapi-cms/content-types/HTML'

const ShippingCosts = () => (
  <StaticQuery
    query={graphql`
      query {
        strapiPage(Title: { regex: "/Delivery Shipping/" }) {
          DefaultContent {
            ... on PageHtml {
              HTML {
                HTML
                CSS
              }
            }
          }
        }
      }
    `}
    render={data => {
      const html = data?.strapiPage?.DefaultContent[0]?.HTML
      return <StrapiHtml data={html} />
    }}
  />
)

export default ShippingCosts
